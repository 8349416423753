<template>
  <div class="container pt-24 mx-auto lg:pt-32">



    <div class="flex flex-wrap justify-center w-full bg-white">
      <div class="cont-article md:w-full">
        <article class="article-destacado">
          <div class="article-icon">
            <font-awesome-icon icon="pencil-ruler" />
          </div>
          <h2 class="article-title">diseño, arquitectura e ingenieria</h2>
          <p class="parrafo-pagina">
            Con más de 38 años ininterrumpidos al servicio de la profesión a nivel nacional como en el extranjero, interactuando con actores del sector público y privado, se atesora un bagaje de conocimientos teóricos, técnicos, legales y normativos.  Los mismos permitieron la formación de un equipo multidisciplinario de colaboradores y asesores, altamente capacitado y profesionalizado que, apoyados en las últimas tecnologías de comunicación, de diseño y de construcción existentes en el mercado, brinda al Estudio Fernando Lopez una eficiente y eficaz labor de equipo.
          </p>
        </article>
      </div>
    </div>
    <div class="flex flex-wrap justify-center w-full bg-white mb-7">


      <div class="cont-article lg:w-1/2">
        <article class="article-gris">
          <div class="article-icon">
            <font-awesome-icon icon="chalkboard-teacher" />
          </div>
          <h2 class="article-title">
            gerencia de proyectos
          </h2>
            <p class="parrafo-pagina">
              A través del desarrollo de distintos proyectos, abarcando las áreas de oficinas corporativas, edificios de carácter público con asistencia masiva de personas, plantas industriales, centros educativos, centros de transmisión de datos, espacios urbanos, viviendas residenciales sustentables y ecológicas, viviendas en propiedad horizontal, el equipo liderado por un responsable de proyecto, ha acumulado una cantidad considerable de experiencia en el desarrollo de los mismos, la cual garantiza futuros emprendimientos del Estudio Fernando Lopez.
          </p>
        </article>
      </div>

      <div class="cont-article lg:w-1/2">
        <article class="article-gris">
          <div class="article-icon">
            <font-awesome-icon icon="building" />
          
          </div>
          <h2 class="article-title">
            gerencia de obras
          </h2>
          <p class="parrafo-pagina">
            Los esfuerzos del Estudio no solo se concentran en la diversidad de servicios capaces de brindar, sino que también se orientan en alcanzar la calidad total del producto, entendiendo al factor <strong>económico</strong> y al factor <strong>plazos</strong> como otros elementos que forman parte de la misma.
          </p>
          <p class="parrafo-pagina">La calidad además de controlarla hay que producirla y monitorearla en todas sus etapas para que, ante cualquier desvío, se pueda actuar rápidamente evitando su propagación en la continuidad del proceso productivo.</p>
        </article>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
